<template>
  <vuestic-widget  :loading="isLoadingContact">
    <legend><strong>Edit Contact</strong></legend>
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <form v-if="selectedContact" @submit.prevent="handleSubmit(saveNewContact)">
        <div class="row">
          <div class="col-md-6">
            <text-input name="First Name" v-model="selectedContact.first_name" label="First Name"/>
          </div>
          <div class="col-md-6">
            <text-input name="Last Name" v-model="selectedContact.last_name" label="Last Name"/>
          </div>
          <div class="col-md-6">
            <phone-number-input name="Phone Number" v-model="selectedContact.number" label="Phone Number"/>
          </div>
          <div class="col-md-6">
            <text-input type="email" name="Email" v-model="selectedContact.email" label="Email"/>
          </div>
          <div class="col-md-6">
            <BirthdayInput v-model="selectedContact.bday" />
          </div>
          <div class="col-md-6">
            <BirthdayInput v-model="selectedContact.anniversary" label="Anniversary" />
          </div>
          <div class="col-md-6">
            <text-input name="City" v-model="selectedContact.city" label="City"/>
          </div>
          <div class="col-md-6 mt-4">
            <div class="form-group">
              <div class="input-group">
                <textarea
                  v-model="selectedContact.note"
                  name="Notes"
                  class="form-control w-100"
                  maxlength="299"
                  rows="3"
                ></textarea>
                <label class="control-label" for="Message">Notes</label>
                <i class="bar"></i>
              </div>
            </div>
            <SMSLength :text="selectedContact.note" />
          </div>
        </div>
        <CustomFields v-model="selectedContact.custom_fields" />
        <div class="row">
          <div class="col-md-12 mt-4 mb-4">
            <tag-selector
              v-model="tagsSelected"
            ></tag-selector>
          </div>
          <div class="col-md-12 mt-4">
            <div class="form-group">
              <div class="input-group">
                <label class="control-label" for="bday">Automations In Process</label>
                <div class="tag-wrapper">
                  <span
                    class="add-tag-btn mr-2 noselect"
                    href="javascript:;"
                    @click="onAddAutomationClick()"
                    ><i class="fa fa-plus"></i> Start Automation</span
                  >
                  <template>
                    <span
                      class="tag noselect"
                      @click="onDeleteAutomation(item)"
                      v-for="(item, key) in selectedContact.automations"
                      :key="key"
                      v-b-tooltip.hover
                      :title="getAutomationTooltip(item)"
                    >
                      {{ item.name }} <span v-if="item.processes.length > 1">( {{ item.processes.length }} )</span>
                      <i class="fa fa-close"></i>
                    </span>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <div class="custom-checkbox-wrapper d-inline-block">
              <label class="checkbox-label">
                <input type="checkbox" id="confirm-checkbox" v-model="isConfirmed">
                <span class="checkbox-custom"></span>
              </label>
            </div>
            <span class="d-inline-block ml-2" style="cursor: pointer" @click="isConfirmed = !isConfirmed">We have permission to contact this person via text message.</span>
          </div>
          <div class="col-md-12 mt-3">
            <div class="custom-checkbox-wrapper d-inline-block">
              <label class="checkbox-label">
                <input type="checkbox" id="confirm-checkbox" v-model="isPromotionOn">
                <span class="checkbox-custom"></span>
              </label>
            </div>
            <span class="d-inline-block ml-2" style="cursor: pointer" @click="isPromotionOn = !isPromotionOn">We have permission to send promotional calls and texts via an automated system.</span>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-6">
            <h5 class="mb-4">Network Information</h5>
            <p><b>Ip</b>: {{ selectedContact.ip || 'N/A' }}</p>
            <p><b>Device</b>: {{ selectedContact.device || 'N/A' }}</p>
            <p v-if="selectedContact.location && selectedContact.location.network"><b>Network Organization</b>: {{ selectedContact.location.network.autonomous_system_organization || 'N/A' }}</p>
            <p v-if="selectedContact.location && selectedContact.location.security"><b>Vpn</b>: {{ selectedContact.location.security.vpn ? 'True' : 'False' }}</p>
          </div>
          <div v-if="selectedContact.location && selectedContact.location.location" class="col-md-6">
            <h5 class="mb-4">Location</h5>
            <p><b>City</b>: {{ selectedContact.location.location.city }}</p>
            <p><b>Region</b>: {{ selectedContact.location.location.region }}</p>
            <p><b>Country</b>: {{ selectedContact.location.location.country }}</p>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-primary" :disabled="processing || invalid">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"/>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>

    <vuestic-modal
      :isOpen="isOpenModalAutomationAdd"
      @cancel="onCloseModalAutomation"
      :cancelShown="false"
      :okShown="false"
    >
      <span slot="title">Run Automation</span>

      <ValidationObserver v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="handleSubmit(onAddAutomation)">
          <div class="row">
            <div class="col-md-12 mb-4">
              <label class="input-label">Automation</label>
              <multiselect
                :show-labels="false" 
                v-model="selectedAutomation"
                :options="automations"
                :multiple="false"
                :preserve-search="true"
                :allowEmpty="false"
                placeholder="Select Automation"
                label="name"
                track-by="id"
              >
              </multiselect>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button class="btn btn-primary" :disabled="automationProcessing || invalid">
                <atom-spinner v-if="automationProcessing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"/>
                <span v-else>Start</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>

    <vuestic-modal
      :isOpen="isOpenModalAutomationDelete"
      @ok="handleDeleteAutomation"
      @cancel="isOpenModalAutomationDelete = false"
      okText="Stop"
      cancelText="Cancel"
      :closeOnOk="false"
      :processing="automationProcessing"
      okClass="btn btn-danger btn-primary"
    >
      <span slot="title" class="text-danger font-weight-bold">Stop Automation?</span>

      <div>Confirm you want to stop the <b>{{ selectedAutomationProcess ? selectedAutomationProcess.name: 'N/A' }}</b> automation?</div>
    </vuestic-modal>
  </vuestic-widget>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import components from '../../common/tables/comps'
import TagSelector from '../../common/TagSelector'
import SMSLength from '../../common/SMSLength'
import CustomFields from './CustomFields'

export default {
  components: {
    TagSelector,
    SMSLength,
    CustomFields
  },

  data () {
    return {
      selectedContact: null,
      tagsSelected: [],
      automations: [],
      isConfirmed: false,
      isPromotionOn: true,
      selectedAutomation: null,
      isOpenModalAutomationAdd: false,
      automationProcessing: false,
      isLoadingContact: false,
      selectedAutomationProcess: null,
      isOpenModalAutomationDelete: false,
    }
  },

  mounted () {
    this.getContact()
  },

  methods: {
    onDeleteAutomation(item) 
    {
      this.isOpenModalAutomationDelete = true;
      this.selectedAutomationProcess = item;
    },

    handleDeleteAutomation()
    {
      const params = {
        contactId: this.selectedContact.id, 
        automationId: this.selectedAutomationProcess.id,
      }

      this.automationProcessing = true

      this.$store
        .dispatch('contact/stopAutomation', params)
        .then(() => {
          this.isOpenModalAutomationDelete = false;
          this.automationProcessing = false
        })
        .catch(() => {
          this.isOpenModalAutomationDelete = false;
        })
    },

    initData(contact) {
      this.selectedContact = contact
      this.tagsSelected = contact.tags
      this.isConfirmed = contact.type === 'sms'
      this.isPromotionOn = !contact.promotion_off
    },

    getContact () {
      this.isLoadingContact = true
      this.$store
        .dispatch('contact/find', this.$route.params.id)
        .then((contact) => {
          this.initData(contact)
          this.isLoadingContact = false
        })
        .catch(() => {
          this.$router.push({ name: 'business.contacts.index' })
        })
    },

    onAddAutomationClick () {
      this.$store.dispatch('automation/all')
        .then((res) => {
          this.automations = res.filter(item => item.active)
          this.selectedAutomation = this.automations.length ? this.automations[0]: null
        })
        .catch(() => {
        })

      this.isOpenModalAutomationAdd = true
    },

    onCloseModalAutomation () {
      this.isOpenModalAutomationAdd = false
    },

    onAddAutomation () {
      if (!this.selectedAutomation) {
        Vue.$toast.open({
          message: 'Please select automation to start.',
          type: 'error',
        })
        return false
      }

      const params = {
        contactId: this.selectedContact.id, 
        automationId: this.selectedAutomation.id,
      }

      this.automationProcessing = true

      this.$store
        .dispatch('contact/startAutomation', params)
        .then((res) => {
          this.automationProcessing = false
          this.isOpenModalAutomationAdd = false
          if (!this.selectedContact.automations.find(item => item.id == res.id))
          {
            this.selectedContact.automations.push(res)
          }
        })
        .catch(() => {
          this.automationProcessing = false
        })
    },

    saveNewContact () {
      const numberObj = parsePhoneNumberFromString(this.selectedContact.number)
      const type = this.isConfirmed ? 'sms' : 'cms'
  
      const payload = {
        id: this.selectedContact.id,
        data: {
          ...this.selectedContact,
          tags: this.tagsSelected,
          number: numberObj.number,
          promotion_off: !this.isPromotionOn,
          type,
        }
      }

      this.$store.dispatch('contact/update', payload).then((contact) => {
        this.initData(contact)
      })
    },

    getAutomationTooltip (item) {
      let result = '';
      if (item.processes.length) {
        let endAt = null;
        let startAt = null;
        item.processes.forEach(process => {
          startAt = process.start_at ? process.start_at : null
          endAt = process.end_at ? process.end_at : null
        })
        if (!startAt)
          startAt = item.created_at
        if (endAt)
        {
          result += `Completed: ${this.$options.filters.shorttime(endAt)}`
        } else if (startAt)
        {
          result += `Started: ${this.$options.filters.shorttime(startAt)}`
        }

        let completed = item.processes.filter(process => process.end_at).length
        let percent = parseInt(completed / item.processes.length * 100);
        result += ` - ${percent}%`
      } else {
        result += `Started: ${this.$options.filters.shorttime(item.created_at)} - 0%`
      }
      return result
    },
  },
  computed: {
    ...mapState('contact', {
      processing: 'processing',
    })
  }
};
</script>

<style scoped lang="scss">
  .createContactForm {
    max-width: 768px;
  }
  .checkbox-label {
    --primaryColor: #3578c6;
  }
  .custom-checkbox-wrapper .checkbox-label .checkbox-custom {
    border-color: $text-gray;
    top: 4px;
    height: 17px;
    width: 17px;
  }

  .custom-checkbox-wrapper .checkbox-label input:checked ~ .checkbox-custom::after {
    left: 5px;
    top: 0px;
  }

  .tag-wrapper {
    span.add-tag-btn {
      display: inline-block;
      border: 1px dashed #ccc;
      border-radius: 5px;
      padding: 2px 5px;
      margin-bottom: 5px;
      cursor: pointer;
      font-size: 14px;
      &:hover {
        border-color: #3578c6;
        color: #3578c6;
      }
    }
    span.tag {
      cursor: pointer;
      background-color: #3578c6;
      color: #fff;
      border-radius: 5px;
      padding: 3px 5px;
      font-size: 0.85em;
      display: block;
      margin-right: 5px;
      display: inline-block;
      margin-bottom: 5px;
      i {
        background-color: #325f94;
        padding: 2px 3px;
        border-radius: 50%;
      }
      &:hover i {
        background-color: #15457d;
      }
    }
  }
</style>